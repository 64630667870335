<template>
  <div>
    <head-layout head-title="任务基本信息" :head-btn-options="headBtnOptions" @head-save="headSave(false)"
      @head-cancel="headCancel"></head-layout>
    <div class="formContentBox">
      <el-form ref="dataForm" :disabled="queryType == 'view'" :model="dataForm" label-width="150px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="模块名称：" prop="moduleName">
              <el-input v-model="dataForm.moduleName" type="text" show-word-limit maxlength="60"
                placeholder="请填写模块名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="负责人：" prop="director">
              <el-input v-model="dataForm.director" type="text" show-word-limit maxlength="30"
                placeholder="请填写负责人"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="表编码：" prop="tableName">
              <el-input v-model="dataForm.tableName" type="text" show-word-limit maxlength="120"
                placeholder="请填写表编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="项目id字段：" prop="projectFields">
              <el-input v-model="dataForm.projectFields" type="text" show-word-limit maxlength="60"
                placeholder="请填写项目id字段"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="对应一级菜单：" prop="firstLevelMenu">
              <el-input v-model="dataForm.firstLevelMenu" type="text" show-word-limit maxlength="60"
                placeholder="请填写对应一级菜单"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="排序号：" prop="soft">
              <el-input v-model="dataForm.soft" type="text" show-word-limit maxlength="30"
                placeholder="请填写排序号"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="配置类型：" prop="configurationType">
              <el-radio v-model="dataForm.configurationType" label="1">语句</el-radio>
              <el-radio v-model="dataForm.configurationType" label="2">接口</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="配置内容：" prop="configurationStatement">
              <el-input v-model="dataForm.configurationStatement" type="textarea" show-word-limit placeholder="请填写配置语句"
                class="sqlInput"></el-input>
              <div v-if="dataForm.configurationType == 1" style="color: red;">
                示例： SELECT company_id projectId, max( company_name ) projectName,<br>
                count( 0 ) projectCount FROM hse_prj.dbt_before_class WHERE is_deleted = 0 <br>
                AND company_id IS NOT NULL AND company_name IS NOT NULL <br>
                GROUP BY company_id <br>
                说明： <br>
                项目id: projectId<br>
                项目名称: projectName<br>
                各项目对应数据数量:projectCount
              </div>
              <div v-else style="color: red;">
                示例：接口内容
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import * as API from "@/api/dbtAppliedTatistics/index";
import HeadLayout from "@/views/components/layout/head-layout";
export default {
  name: 'HseWebEdit',
  components: {
    HeadLayout,
  },
  data() {
    return {
      dataForm: {
        moduleName: '',
        director: '',
        tableName: '',
        projectFields: '',
        firstLevelMenu: '',
        soft: '',
        configurationType: '1', // 配置类型
        configurationStatement: ''
      },
      rules: {
        moduleName: [
          { required: true, message: '请输入模块名称', trigger: 'blur' },
          { min: 1, max: 60, message: '长度不能超过60', trigger: 'blur' }
        ],
        director: [
          { required: true, message: '请填写负责人', trigger: 'blur' },
          { min: 1, max: 30, message: '长度不能超过30', trigger: 'blur' }
        ],
        tableName: [
          { required: true, message: '请填写表编码', trigger: 'blur' },
          { min: 1, max: 120, message: '长度不能超过120', trigger: 'blur' }
        ],
        projectFields: [
          { required: true, message: '请填写项目id字段', trigger: 'blur' },
          { min: 1, max: 60, message: '长度不能超过60', trigger: 'blur' }
        ],
        firstLevelMenu: [
          { required: true, message: '请填写对应一级菜单', trigger: 'blur' },
          { min: 1, max: 60, message: '长度不能超过60', trigger: 'blur' }
        ],
        soft: [
          { required: true, message: '请填写排序号', trigger: 'blur' },
          { min: 1, max: 30, message: '长度不能超过30', trigger: 'blur' }
        ],
        configurationStatement: [
          { required: true, message: '请填写配置语句', trigger: 'blur' },
        ],
      },
      queryType: '',
    };
  },

  mounted() {
    let queryType = this.$route.query.type;
    let queryRow = this.$route.query.row;
    this.queryType = queryType
    console.log(queryRow);
    if (queryType == 'view') {
      this.getData(queryRow)
    }
    if (queryType == 'edit') {
      this.editData(queryRow)
    }
  },
  computed: {
    headBtnOptions() {
      let buttonList = [
        {
          label: "取消",
          emit: "head-cancel",
          type: "button",
          btnOptType: "back",
        },
        {
          label: "保存",
          emit: "head-save",
          type: "button",
          btnOptType: "save",
        },
      ]
      return buttonList
    }
  },
  methods: {
    getData(row) {
      this.dataForm = row
    },
    editData(row) {
      this.dataForm = row
    },
    // 取消
    headCancel() {
      this.$refs.dataForm.resetFields();
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 保存
    headSave() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          var data = { ...this.dataForm }
          data = JSON.parse(JSON.stringify(data))
          API.createOrUpdate(data).then(res => {
            if (res.data.code == 200) {
              this.$router.$avueRouter.closeTag();
              this.$router.back();
            }
          })
        } else {
          console.log('请补充表单信息！');
          return false;
        }
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.formContentBox {
  background-color: #fff;
  padding: 10px;
}

::v-deep .el-textarea__inner {
  min-height: 150px !important;
}
</style>